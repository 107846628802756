// Uniswap & Sushiswap
export const UNISWAP_FACTORY_ADDRESS = "0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f";
export const SUSHISWAP_FACTORY_ADDRESS = '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac';
export const UNISWAP_ROUTER_ADDRESS = "0x7a250d5630b4cf539739df2c5dacb4c659f2488d";

export const NDX_ADDRESS = "0x86772b1409b61c639EaAc9Ba0AcfBb6E238e5F83";
export const WETH_CONTRACT_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
export const SUSHI_ADDRESS = "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2";

export const MINT_ROUTER_ADDRESS = "0xfb6ac20d38a1f0c4f90747ca0745e140bc17e4c3";
export const BURN_ROUTER_ADDRESS = "0x348ab9b021fff6016d3eb07d3171bdef0022cfa8";

export const MULTI_TOKEN_STAKING_ADDRESS = "0xc46e0e7ecb3efcc417f6f89b940ffaff72556382";
export const REWARDS_SCHEDULE_ADDDRESS = "0x131ba0fc3e4e866e5daf3d16526846fdd3e67623";
export const MASTER_CHEF_ADDRESS = "0xc2EdaD668740f1aA35E4D8f227fB8E17dcA888Cd";

export const ETH_BALANCE_GETTER = "0xfF1c691C3DB6fA065B6499A5c93264a6e5cb8ed2";
export const MULTICALL2_ADDRESS = "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696";

export const NARWHAL_ROUTER_ADDRESS = "0x429302C74a0350410fC8B43E4839D459dEC4D050";